import React, {Component} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';
import ByCity from './../Element/ByCity';

class AllJobsByCity extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Explore All Jobs by Popular USA Cities</title>
                    <meta name="description" content="Explore a variety of job opportunities categorized by popular USA cities. Find your next remote work opportunity. #OpenToWorkRemote" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/all-jobs-by-city" />
                </Helmet>

                <ByCity />

                <Footer />
            </div>
        );
    }
};

export default AllJobsByCity;
