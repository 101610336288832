import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Jobcategories extends Component {
    render() {
        return (
            <div className="row sp20">
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <Link to={"/remote-jobs-backend"} className="dez-tilte">
                        <div className="icon-bx-wraper">
                            <div className="icon-content">
                                <div className="icon-md text-primary m-b20"><i className="ti-settings"></i></div>
                                <span className="dez-tilte">Backend Jobs</span>
                                <p className="m-a0">&nbsp;</p>
                                <div className="rotate-icon"><i className="ti-settings"></i></div> 
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <Link to={"/remote-jobs-frontend"} className="dez-tilte">
                        <div className="icon-bx-wraper">
                            <div className="icon-content">
                                <div className="icon-md text-primary m-b20"><i className="ti-desktop"></i></div>
                                <span className="dez-tilte">Frontend Jobs</span>
                                <p className="m-a0">&nbsp;</p>
                                <div className="rotate-icon"><i className="ti-desktop"></i></div> 
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <Link to={"/remote-jobs-devops"} className="dez-tilte">
                        <div className="icon-bx-wraper">
                            <div className="icon-content">
                                <div className="icon-md text-primary m-b20"><i className="ti-panel"></i></div>
                                <span className="dez-tilte">DevOps Jobs</span>
                                <p className="m-a0">&nbsp;</p>
                                <div className="rotate-icon"><i className="ti-panel"></i></div> 
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <Link to={"/remote-jobs-marketing"} className="dez-tilte">
                        <div className="icon-bx-wraper">
                            <div className="icon-content">
                                <div className="icon-md text-primary m-b20"><i className="ti-bar-chart"></i></div>
                                <span className="dez-tilte">Marketing Jobs</span>
                                <p className="m-a0">&nbsp;</p>
                                <div className="rotate-icon"><i className="ti-bar-chart"></i></div> 
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-12 text-center m-t30" style={{display: ""}}>
                    <Link to={"/all-jobs-by-category"} className="dez-tilte">
                        <button className="site-button radius-l button-lg" style={{
                            transition: "all 0.3s ease",
                            padding: "12px 25px"
                        }}>
                            View More Categories <i className="ti-arrow-right" style={{
                                marginLeft: "8px",
                                transition: "transform 0.3s ease"
                            }}></i>
                        </button>
                    </Link>
                </div>
            </div>
        );
    }
};

export default Jobcategories;
