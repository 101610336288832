import React, {Component} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';
import ByState from './../Element/ByState';

class AllJobsByState extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Explore Job Listings by State Across the USA</title>
                    <meta name="description" content="Explore a variety of job opportunities categorized by state Across the USA. Find your next remote work opportunity. #OpenToWorkRemote" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/all-jobs-by-state" />
                </Helmet>

                <ByState />

                <Footer />
            </div>
        );
    }
};

export default AllJobsByState;
