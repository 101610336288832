import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';

const randomNumber = Math.floor(Math.random() * 5) + 10;
const bnr = require('./../../images/banner/bnr' + randomNumber + '.jpg');

class AllJobsBySource extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Discover All Jobs by Source</title>
                    <meta name="description" content="Explore a variety of job opportunities categorized by location. Find your next remote work opportunity. #OpenToWorkRemote" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/all-jobs-by-source" />
                </Helmet>

                <div className="page-content">
                    <div className="dez-bnr-inr jobs-category overlay-black-middle" style={{backgroundImage: "url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <Link to = {"/"}>
                                    <h1 className="text-white">
                                        Browse Job Listings by Source
                                    </h1>
                                </Link>
                                <div className="breadcrumb-row">
                                    <ul className="">
                                        <li>A thank you page for friendly pages that share remote jobs</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="section-full content-inner jobs-category-bx">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 m-b30">
                                        <div className="job-bx bg-white">
                                            <div className="job-bx-title clearfix">
                                                <h6 className="text-uppercase">Explore Jobs By His Source:</h6>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs-from-remoteok">RemoteOK</Link></li>
                                                        <li><Link to="/jobs-from-remotive">Remotive</Link></li>
                                                        <li><Link to="/jobs-from-4dayweek">4DayWeek</Link></li>
                                                        <li><Link to="/jobs-from-echojobs">EchoJobs</Link></li>
                                                        <li><Link to="/jobs-from-weworkremotely">WeWorkRemotely</Link></li>
                                                        <li><Link to="/jobs-from-workable">Workable</Link></li>
                                                        <li><Link to="/jobs-from-infojobs">InfoJobs</Link></li>
                                                        <li><Link to="/jobs-from-workingnomads">WorkingNomads</Link></li>
                                                        <li><Link to="/jobs-from-getonbrd">GetOnBrd</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-4">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs-from-authenticjobs">AuthenticJobs</Link></li>
                                                        <li><Link to="/jobs-from-spacecrew">SpaceCrew</Link></li>
                                                        <li><Link to="/jobs-from-tryremotely">TryRemotely</Link></li>
                                                        <li><Link to="/jobs-from-jobicy">Jobicy</Link></li>
                                                        <li><Link to="/jobs-from-greenhouse">Greenhouse</Link></li>
                                                        <li><Link to="/jobs-from-himalayas">Himalayas</Link></li>
                                                        <li><Link to="/jobs-from-landingjobs">LandingJobs</Link></li>
                                                        <li><Link to="/jobs-from-larajobs">LaraJobs</Link></li>
                                                        <li><Link to="/jobs-from-coroflot">Coroflot</Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-4">
                                                    <ul className="category-list">
                                                        <li><Link to="/jobs-from-crunchboard">CrunchBoard</Link></li>
                                                        <li><Link to="/jobs-from-zobjobs">ZobJobs</Link></li>
                                                        <li><Link to="/jobs-from-sportstech">SportsTech</Link></li>
                                                        <li><Link to="/jobs-from-dribbble">Dribbble</Link></li>
                                                        <li><Link to="/jobs-from-vuejobs">VueJobs</Link></li>
                                                        <li><Link to="/jobs-from-sveltejobs">SvelteJobs</Link></li>
                                                        <li><Link to="/jobs-from-remote3">Remote3</Link></li>
                                                        <li><Link to="/jobs-from-woodyjobs">WoodyJobs</Link></li>
                                                        <li><Link to="/jobs-from-lever">Lever</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default AllJobsBySource;
