import React, {Component} from 'react';
import '../../css/select2-custom.css';

class Jobfindbox2 extends Component {

    state = {
        title: '',
        tag: '',
        location: '',
        salaryMin: ''
    }

    changeTitle = (e) => {
        this.setState({
            title: e.target.value,
        });
        const form = e.target.form;
        if (form) {
            form.submit();
        }
    }

    changeTag = (e) => {
        this.setState({
            tag: e.target.value,
        });
    }

    changeLocation = (e) => {
        this.setState({
            location: e.target.value,
        });
        const form = e.target.form;
        if (form) {
            form.submit();
        }
    }

    changeSalaryMin = (e) => {
        this.setState({
            salaryMin: e.target.value,
        });
    }

    componentDidMount() {

        var params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop)
        });

        let { myTitle = '', myLocation = '' } = this.props;

        if (params.title) {
            myTitle = params.title;
        }

        if (params.location) {
            myLocation = params.location;
        }

        this.setState({
            title: myTitle || '',
            tag: params.tag || '',
            location: myLocation || '',
            salaryMin: params.salaryMin || '',
        });

        const $ = window.$;

        $(function () {
            $("select").select2({
                tags: true,
                theme: "default",
                containerCssClass: 'custom-select2-container',
                dropdownCssClass: 'custom-select2-dropdown'
            });

            $('#title').on('select2:open', () => {
                document.querySelector('.select2-search__field').focus();
            });

            $('#location').on('select2:open', () => {
                document.querySelector('.select2-search__field').focus();
            });
        });
    }

    render() {

        const $ = window.$;

        var params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop)
        });

        if (params.title) {
            const optionExists = $('#title').find(`option[value="${params.title}"]`).length > 0;
            if (!optionExists) {
                $('#title').append(new Option(params.title, params.title));
            }
        }

        if (params.location) {
            const optionExists = $('#location').find(`option[value="${params.location}"]`).length > 0;
            if (!optionExists) {
                $('#location').append(new Option(params.location, params.location));
            }
        }

        let { myTitle = '', myLocation = '' } = this.props;

        if (params.title) {
            myTitle = params.title;
        }

        if (params.location) {
            myLocation = params.location;
        }

        let url = '/jobs';
        let queryParams = [];

        $('#title').on('select2:select', function (e) {
            queryParams.push('title=' + encodeURIComponent(e.target.value));
            if (myLocation) {
                queryParams.push('location=' + encodeURIComponent(myLocation));
            }
            url += '?' + queryParams.join('&');
            window.location.href = url;
        });

        $('#location').on('select2:select', function (e) {
            if (myTitle) {
                queryParams.push('title=' + encodeURIComponent(myTitle));
            }
            queryParams.push('location=' + encodeURIComponent(e.target.value));
            url += '?' + queryParams.join('&');
            window.location.href = url;
        });

        return (
            <div className="section-full browse-job-find">
                <div className="container">
                    <div className="find-job-bx">
                        <form id="searcher" className="dezPlaceAni text-left">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label></label>
                                        <div className="input-group">
                                            <div className="input-group-append" style={{display: "none"}}>
                                                <span className=""><i className="fa fa-search"></i>&nbsp;&nbsp;&nbsp;</span>
                                            </div>
                                            {/* eslint-disable jsx-a11y/accessible-emoji */}
                                            <select className="form-control" id="title" name="title" value={this.state.title} onChange={this.changeTitle} >
                                                <option value="">🔎 Search by Job Title</option>
                                                <option value="Backend">Backend</option>
                                                <option value="Frontend">Frontend</option>
                                                <option value="Full Stack">Full Stack</option>
                                                <option value="Marketing">Marketing</option>
                                                <option value="Sales ">Sales</option>
                                                <option value="QA">QA</option>
                                                <option value="HR ">HR</option>
                                                <option value="Sourcer">Sourcer</option>
                                                <option value="Recruiter">Recruiter</option>
                                                <option value="Talent Acquisition">Talent Acquisition</option>
                                                <option value="Customer Success">Customer Success</option>
                                                <option value="Customer Success Manager">Customer Success Manager</option>
                                                <option value="Customer Support">Customer Support</option>
                                                <option value="Customer Service">Customer Service</option>
                                                <option value="Help Desk">Help Desk</option>
                                                <option value="Call Center">Call Center</option>
                                                <option value="Head of Product">Head of Product</option>
                                                <option value="Product Manager">Product Manager</option>
                                                <option value="Product Owner">Product Owner</option>
                                                <option value="Product Designer">Product Designer</option>
                                                <option value="Product Engineer">Product Engineer</option>
                                                <option value="Product Analyst">Product Analyst</option>
                                                <option value="Project Manager">Project Manager</option>
                                                <option value="Program Manager">Program Manager</option>
                                                <option value="Software Engineer">Software Engineer</option>
                                                <option value="Senior Software Engineer">Senior Software Engineer</option>
                                                <option value="Solutions Engineer">Solutions Engineer</option>
                                                <option value="Technical Support">Technical Support</option>
                                                <option value="Community Manager">Community Manager</option>
                                                <option value="Social Media">Social Media</option>
                                                <option value="Graphic Designer">Graphic Designer</option>
                                                <option value="Designer">Designer</option>
                                                <option value="Finance">Finance</option>
                                                <option value="Data Entry">Data Entry</option>
                                                <option value="Data Analyst">Data Analyst</option>
                                                <option value="Data Engineer">Data Engineer</option>
                                                <option value="Data Architect">Data Architect</option>
                                                <option value="Data Scientist">Data Scientist</option>
                                                <option value="Scrum Master">Scrum Master</option>
                                                <option value="Security Engineer">Security Engineer</option>
                                                <option value="Automation">Automation</option>
                                                <option value="Game Tester">Game Tester</option>
                                                <option value="SDET">SDET</option>
                                                <option value="Part Time">Part Time</option>
                                                <option value="SEO">SEO</option>
                                                <option value="UI/UX">UI/UX</option>
                                                <option value="DevOps">DevOps</option>
                                                <option value="SRE">SRE</option>
                                                <option value="Site Reliability Engineer">Site Reliability Engineer</option>
                                                <option value="System Administrator">System Administrator</option>
                                                <option value="Infrastructure Engineer">Infrastructure Engineer</option>
                                                <option value="Network Engineer">Network Engineer</option>
                                                <option value="Information Security">Information Security</option>
                                                <option value="Cybersecurity">Cyber Security</option>
                                                <option value="DevSecOps">DevSecOps</option>
                                                <option value="Release Engineer">Release Engineer</option>
                                                <option value="Release Manager">Release Manager</option>
                                                <option value="Human Resources">Human Resources</option>
                                                <option value="Administrator">Administrator</option>
                                                <option value="xecutive Assistant">Executive Assistant</option>
                                                <option value="Virtual Assistant">Virtual Assistant</option>
                                                <option value="Sales Engineer">Sales Engineer</option>
                                                <option value="Sales Development">Sales Development</option>
                                                <option value="Copywriter">Copywriter</option>
                                                <option value="Content Writer">Content Writer</option>
                                                <option value="Technical Writer">Technical Writer</option>
                                                <option value="Underwrit">Underwriting</option>
                                                <option value="Platform Engineer">Platform Engineer</option>
                                                <option value="Business Development">Business Development</option>
                                                <option value="Business Operations">Business Operations</option>
                                                <option value="Business Analyst">Business Analyst</option>
                                                <option value="Business Intelligence">Business Intelligence</option>
                                                <option value="Operations Manager">Operations Manager</option>
                                                <option value="Engineering Manager">Engineering Manager</option>
                                                <option value="Electrical Engineer">Electrical Engineer</option>
                                                <option value="Mechanical Engineer">Mechanical Engineer</option>
                                                <option value="Support Engineer">Support Engineer</option>
                                                <option value="Account Manager">Account Manager</option>
                                                <option value="Account Exe">Account Executive</option>
                                                <option value="Accountant">Accountant</option>
                                                <option value="Payroll">Payroll</option>
                                                <option value="Paid Media">Paid Media</option>
                                                <option value="Compensation">Compensation</option>
                                                <option value="Consultant">Consultant</option>
                                                <option value="Video Editor">Video Editor</option>
                                                <option value="Animator">Animator</option>
                                                <option value="Artist">Artist</option>
                                                <option value="Auditor">Auditor</option>
                                                <option value="Attorney">Attorney</option>
                                                <option value="Lawyer">Lawyer</option>
                                                <option value="Counsel">Counsel</option>
                                                <option value="Legal">Legal</option>
                                                <option value="Risk">Risk</option>
                                                <option value="VP">VP</option>
                                                <option value="Jr">Jr</option>
                                                <option value="Junior">Junior</option>
                                                <option value="Supply Chain">Supply Chain</option>
                                                <option value="Logistics">Logistics</option>
                                                <option value="Freelance">Freelance</option>
                                                <option value="Developer">Developer</option>
                                                <option value="Web Developer">Web Developer</option>
                                                <option value="Mobile">Mobile</option>
                                                <option value="iOS">iOS</option>
                                                <option value="Android">Android</option>
                                                <option value="PHP">PHP</option>
                                                <option value="Laravel">Laravel</option>
                                                <option value="Symfony">Symfony</option>
                                                <option value="Wordpress">Wordpress</option>
                                                <option value="Java ">Java</option>
                                                <option value="JavaScript">JavaScript</option>
                                                <option value="TypeScript">TypeScript</option>
                                                <option value="React">React</option>
                                                <option value="Angular">Angular</option>
                                                <option value="Vue.js">Vue.js</option>
                                                <option value="Python">Python</option>
                                                <option value="Django">Django</option>
                                                <option value="Node.js">Node.js</option>
                                                <option value=".NET">.NET</option>
                                                <option value="Golang">Golang</option>
                                                <option value="C++">C++</option>
                                                <option value="C#">C#</option>
                                                <option value="Rust">Rust</option>
                                                <option value="Scala">Scala</option>
                                                <option value="Ruby">Ruby</option>
                                                <option value="Ruby on Rails">Ruby on Rails</option>
                                                <option value="Blockchain">Blockchain</option>
                                                <option value="Solidity">Solidity</option>
                                                <option value="Web3">Web3</option>
                                                <option value=" AI ">AI</option>
                                                <option value="AWS">AWS</option>
                                                <option value="Tech Lead">Tech Lead</option>
                                                <option value="Power BI">Power BI</option>
                                                <option value="Salesforce">Salesforce</option>
                                                <option value="Machine Learning">Machine Learning</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label></label>
                                        <div className="input-group">
                                            <div className="input-group-append" style={{display: "none"}}>
                                                <span className=""><i className="fa fa-map-marker"></i>&nbsp;&nbsp;&nbsp;</span>
                                            </div>
                                            <select className="form-control" id="location" name="location" value={this.state.location} onChange={this.changeLocation} >
                                                <option value="">📍 Search by Location</option>
                                                <option value="Remote.">🌎 Remote - Worldwide</option>
                                                <option value="United States">🇺🇸 United States</option>
                                                <option value="Argentina">🇦🇷 Argentina</option>
                                                <option value="Armenia">🇦🇲 Armenia</option>
                                                <option value="Australia">🇦🇺 Australia</option>
                                                <option value="Austria">🇦🇹 Austria</option>
                                                <option value="Belgium">🇧🇪 Belgium</option>
                                                <option value="Brazil">🇧🇷 Brazil</option>
                                                <option value="Bulgaria">🇧🇬 Bulgaria</option>
                                                <option value="Canada">🇨🇦 Canada</option>
                                                <option value="Chile">🇨🇱 Chile</option>
                                                <option value="Colombia">🇨🇴 Colombia</option>
                                                <option value="Costa Rica">🇨🇷 Costa Rica</option>
                                                <option value="Croatia">🇭🇷 Croatia</option>
                                                <option value="Cyprus">🇨🇾 Cyprus</option>
                                                <option value="Czechia">🇨🇿 Czechia</option>
                                                <option value="Denmark">🇩🇰 Denmark</option>
                                                <option value="Ecuador">🇪🇨 Ecuador</option>
                                                <option value="El Salvador">🇸🇻 El Salvador</option>
                                                <option value="Egypt">🇪🇬 Egypt</option>
                                                <option value="Estonia">🇪🇪 Estonia</option>
                                                <option value="Ethiopia">🇪🇹 Ethiopia</option>
                                                <option value="Europe">🇪🇺 Europe</option>
                                                <option value="Finland">🇫🇮 Finland</option>
                                                <option value="France">🇫🇷 France</option>
                                                <option value="Gabon">🇬🇦 Gabon</option>
                                                <option value="Germany">🇩🇪 Germany</option>
                                                <option value="Greece">🇬🇷 Greece</option>
                                                <option value="Hungary">🇭🇺 Hungary</option>
                                                <option value="India">🇮🇳 India</option>
                                                <option value="Indonesia">🇮🇩 Indonesia</option>
                                                <option value="Ireland">🇮🇪 Ireland</option>
                                                <option value="Israel">🇮🇱 Israel</option>
                                                <option value="Italy">🇮🇹 Italy</option>
                                                <option value="Japan">🇯🇵 Japan</option>
                                                <option value="LATAM">🌎 LATAM</option>
                                                <option value="Latvia">🇱🇻 Latvia</option>
                                                <option value="Lebanon">🇱🇧 Lebanon</option>
                                                <option value="Liberia">🇱🇷 Liberia</option>
                                                <option value="Lithuania">🇱🇹 Lithuania</option>
                                                <option value="Luxembourg">🇱🇺 Luxembourg</option>
                                                <option value="Malaysia">🇲🇾 Malaysia</option>
                                                <option value="Mexico">🇲🇽 Mexico</option>
                                                <option value="Morocco">🇲🇦 Morocco</option>
                                                <option value="Namibia">🇳🇦 Namibia</option>
                                                <option value="Netherlands">🇳🇱 Netherlands</option>
                                                <option value="New Zealand">🇳🇿 New Zealand</option>
                                                <option value="Nicaragua">🇳🇮 Nicaragua</option>
                                                <option value="Nigeria">🇳🇬 Nigeria</option>
                                                <option value="Norway">🇳🇴 Norway</option>
                                                <option value="Pakistan">🇵🇰 Pakistan</option>
                                                <option value="Peru">🇵🇪 Peru</option>
                                                <option value="Philippines">🇵🇭 Philippines</option>
                                                <option value="Poland">🇵🇱 Poland</option>
                                                <option value="Portugal">🇵🇹 Portugal</option>
                                                <option value="Romania">🇷🇴 Romania</option>
                                                <option value="Serbia">🇷🇸 Serbia</option>
                                                <option value="Singapore">🇸🇬 Singapore</option>
                                                <option value="Slovakia">🇸🇰 Slovakia</option>
                                                <option value="South Africa">🇿🇦 South Africa</option>
                                                <option value="Spain">🇪🇸 Spain</option>
                                                <option value="Sweden">🇸🇪 Sweden</option>
                                                <option value="Switzerland">🇨🇭 Switzerland</option>
                                                <option value="Thailand">🇹🇭 Thailand</option>
                                                <option value="Tunisia">🇹🇳 Tunisia</option>
                                                <option value="Turkey">🇹🇷 Turkey</option>
                                                <option value="United Kingdom">🇬🇧 United Kingdom</option>
                                                <option value="USA">🇺🇸 USA</option>
                                                <option value="Ukraine">🇺🇦 Ukraine</option>
                                                <option value="Uruguay">🇺🇾 Uruguay</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-6" style={{display: "none"}}>
                                    <button type="submit" className="site-button btn-block">Search Jobs</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
};

export default Jobfindbox2;

