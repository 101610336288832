import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import RenderJobs from './../RenderJobs';
import api from './../Api';

const axios = require('axios');

class JobsByCompany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let company = params.q || '';

        axios
            .request(api.getJobs('', '', '', company))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let company = params.q || null;

        if (!company) {
            window.location.href = '/';
            return null;
        }

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>{'Find jobs at ' + company}: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content={'Looking for jobs at ' + company + '? Explore ' + company + ' careers and apply now to find your dream job!'} />
                    <link rel="canonical" href={'https://www.opentoworkremote.com/company/?q=' + company} />
                </Helmet>

                <RenderJobs
                    title={'Find jobs at ' + company}
                    jobs={jobs}
                    total={total}
                    page={page}
                    pages={pages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    isLoading={isLoading}
                />

                <Footer />
            </div>
        );
    }
}

export default JobsByCompany;
