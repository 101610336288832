import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import { Link } from "react-router-dom";
import ContentJobs from './../../Element/ContentJobs';
import Jobfindbox from "./../../Element/Jobfindbox2";

const axios = require('axios');

class JobsJavaScript extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('JavaScript', ''))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Find Jobs in JavaScript: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs in JavaScript? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-javascript" />
                </Helmet>

                <div className="page-content bg-white">
                    <div className="page-content bg-white">
                        <div className="dez-bnr-inr bg-dark">
                            <div className="container">
                                <div className="dez-bnr-inr-entry">
                                    <Link to={""}>
                                        <h1 className="text-white">
                                            Find jobs in JavaScript
                                        </h1>
                                    </Link>
                                    <div className="breadcrumb-row">
                                        <ul className="">
                                            <li>Spot your dream job today. #OpenToWorkRemote</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-2 col-md-2 m-2">
                                &nbsp;
                            </div>
                            <div className="col-lg-4 col-md-4 m-4">
                                <h2>JavaScript Dev Careers</h2>
                                <div>
                                    JavaScript developers bring dynamic and interactive experiences to life on the web, working on both the frontend and backend. They leverage JavaScript along with various frameworks and libraries to build modern, responsive applications that enhance user engagement.
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 m-4">
                                <h2>Career Paths</h2>
                                <div className="mb-6">
                                  <ul className="space-y-2 text-gray-700">
                                    <li className="flex items-center">
                                      <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                      Junior JavaScript Developer
                                    </li>
                                    <li className="flex items-center">
                                      <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                      Senior JavaScript Engineer
                                    </li>
                                    <li className="flex items-center">
                                      <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                      JavaScript Team Lead
                                    </li>
                                    <li className="flex items-center">
                                      <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                      Full Stack JavaScript Developer
                                    </li>
                                    <li className="flex items-center">
                                      <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                                      Node.js Specialist
                                    </li>
                                  </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 m-2">
                                &nbsp;
                            </div>
                        </div>

                        <Jobfindbox myTitle="JavaScript" />

                    </div>

                    <ContentJobs
                        jobs={jobs}
                        total={total}
                        page={page}
                        pages={pages}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        isLoading={isLoading}
                    />
                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsJavaScript;
