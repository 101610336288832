import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { getUrl, getHeaders } from './Api';

const axios = require("axios");

class GoToApply extends Component {

    constructor(props) {
        super(props);
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop)
        });
//        console.log('params.url:', params.url);
        if (params.url) {
            let source = '?ref=opentoworkremote.com&utm_source=opentoworkremote.com';
            if (params.url.toString().indexOf("?") !== -1) {
                source = '&ref=opentoworkremote.com&utm_source=opentoworkremote.com';
            }
//            console.log('preparing redirection to:', params.url + source);
            window.location.replace(params.url + source);
        } else {
            const jobId = window.location.pathname.split('/')[2];
//            console.log('jobId:', jobId);
            this.getJob(jobId);
        }

        this.state = {
            myresponse: []
        };
    }

    getJob(jobId) {
        const options = {
            method: "GET",
            url: `${getUrl()}/${jobId}`,
            params: {},
            headers: getHeaders()
        };

        axios.request(options)
            .then((response) => {
                this.setState({
                    myresponse: response.data
                });
            })
            .catch(function (error) {
                console.error(error);
                if (error.response && error.response.status === 404) {
                    console.log('Job Not Found, redirecting...');
                    window.location.href = '/';
                }
            });
    }

    render() {

        const { myresponse } = this.state;

        if (myresponse.url) {
            let source = '?ref=opentoworkremote.com&utm_source=opentoworkremote.com';
            if (myresponse.url.toString().indexOf("?") !== -1) {
                source = '&ref=opentoworkremote.com&utm_source=opentoworkremote.com';
            }
//            console.log('preparing redirection url by id:', myresponse.url + source);
            window.location.replace(myresponse.url + source);
        }

        const jobId = window.location.pathname.split('/')[2];

        let canonical = "https://www.opentoworkremote.com/apply/" + jobId;

        return (
            <div>

                <Helmet>
                    <title>Apply to the Job & Good Luck! #OpenToWorkRemote</title>
                    <meta name="description" content="We are preparing redirection. Apply & Good Luck!" />
                    <link rel="canonical" href={canonical} />
                    <meta name="robots" content="noindex" data-react-helmet="true" />
                </Helmet>

                <div className="page-wraper">
                    <div className="page-content">
                        <div className="section-full content-inner-3 bg-white">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <h1 className="text-success">We are preparing redirection...</h1>
                                        <h2 className="text-success">Apply & Good Luck!</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
};

export default GoToApply;
