import React, { Component } from "react";
import Footer from "./../../Layout/Footer2";
import Header from "./../../Layout/Header";
import { Helmet } from 'react-helmet';
import api from './../Api';

import TitleJobs from './../../Element/TitleJobs';
import ContentJobs from './../../Element/ContentJobs';

const axios = require('axios');

class JobsFromCoroflot extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            total: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getJobs();
    }

    getJobs() {
        axios
            .request(api.getJobs('', '', 'coroflot'))
            .then((response) => {
                this.setState({
                    jobs: response.data.jobs,
                    total: response.data.pagesInfo.totalJobs,
                    pages: response.data.pagesInfo.totalpages,
                    page: response.data.pagesInfo.page,
                    perPage: response.data.pagesInfo.perPage,
                    prevPage: response.data.pagesInfo.prevPage,
                    nextPage: response.data.pagesInfo.nextPage,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        let { jobs, total, page, pages, prevPage, nextPage, isLoading } = this.state;

        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Jobs from Coroflot: Find your job here on #OpenToWorkRemote!</title>
                    <meta name="description" content="Looking for jobs from Coroflot? Find your dream job here and apply today!" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/jobs-from-coroflot" />
                </Helmet>

                <div className="page-content bg-white">
                    <TitleJobs title='Find jobs from Coroflot' />
                    <ContentJobs jobs={jobs} total={total} page={page} pages={pages} prevPage={prevPage} nextPage={nextPage} isLoading={isLoading} />
                </div>

                <Footer />
            </div>
        );
    }
}

export default JobsFromCoroflot;
