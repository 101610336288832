import React, {Component} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';

import ByCountry from './../Element/ByCountry';
import ByCity from './../Element/ByCity';
import ByState from './../Element/ByState';

class AllJobsByLocation extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Discover All Jobs by Location</title>
                    <meta name="description" content="Explore a variety of job opportunities categorized by location. Find your next remote work opportunity. #OpenToWorkRemote" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/all-jobs-by-location" />
                </Helmet>

                <ByCountry />

                <ByState />

                <ByCity />

                <Footer />
            </div>
        );
    }
};

export default AllJobsByLocation;
