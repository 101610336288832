import React from 'react';
import TitleJobs from './../Element/TitleJobs';
import ContentJobs from './../Element/ContentJobs';

function RenderJobs({ 
    title, 
    jobs, 
    total, 
    page, 
    pages, 
    prevPage, 
    nextPage,
    isLoading 
}) {
    return (
        <div>
            <div className="page-content bg-white">
                <TitleJobs title={title} />
                <ContentJobs
                    jobs={jobs}
                    total={total}
                    page={page}
                    pages={pages}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
}

export default RenderJobs;
