import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <footer className="site-footer">
                <div style={{display: "none"}} className="section-full content-inner-2 call-to-action overlay-black-dark text-white text-center bg-img-fix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="m-b10">Explore the blog</h2>
                                <h5 className="m-b0">Discover and read my latest posts.</h5>
                                <Link to={"/blog"} className="site-button m-t20 outline outline-2 radius-xl">Go to the Blog</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-top">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget border-0">
                                    <h5 className="m-b20 text-white">Jobs By Role</h5>
                                    <ul className="w10 list-line">
                                        <li><Link to={`/jobs-sales`}>Sales</Link></li>
                                        <li><Link to={`/jobs-backend`}>Backend</Link></li>
                                        <li><Link to={`/jobs-frontend`}>Frontend</Link></li>
                                        <li><Link to={`/jobs-fullstack`}>Full Stack</Link></li>
                                        <li><Link to={`/jobs-marketing`}>Marketing</Link></li>
                                        <li><Link to={`/all-jobs-by-category`}>All Categories</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget border-0">
                                    <h5 className="m-b20 text-white">By Location</h5>
                                    <ul className="w10 list-line">
                                        <li><Link to={'/remote-jobs-worldwide'}>Remote</Link></li>
                                        <li><Link to={'/jobs-in-latam'}>LATAM</Link></li>
                                        <li><Link to={'/jobs-in-canada'}>Canada</Link></li>
                                        <li><Link to={'/jobs-in-usa'}>United States</Link></li>
                                        <li><Link to={'/jobs-in-united-kingdom'}>United Kingdom</Link></li>
                                        <li><Link to={'/all-jobs-by-location'}>All Locations</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget border-0">
                                    <h5 className="m-b20 text-white">By Company</h5>
                                    <ul className="w10 list-line">
                                        <li><a href="/company/?q=Airbnb">Airbnb</a></li>
                                        <li><a href="/company/?q=GitLab">GitLab</a></li>
                                        <li><a href="/company/?q=SpaceX">SpaceX</a></li>
                                        <li><a href="/company/?q=Discord">Discord</a></li>
                                        <li><a href="/company/?q=1Password">1Password</a></li>
                                        <li><a href="/company/?q=Automattic">Automattic</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget border-0">
                                    <h5 className="m-b20 text-white">By Source</h5>
                                    <ul className="w10 list-line">
                                        <li><Link to={'/jobs-from-remoteok'}>RemoteOK</Link></li>
                                        <li><Link to={'/jobs-from-remotive'}>Remotive</Link></li>
                                        <li><Link to={'/jobs-from-workable'}>Workable</Link></li>
                                        <li><Link to={'/jobs-from-echojobs'}>EchoJobs</Link></li>
                                        <li><Link to={'/jobs-from-4dayweek'}>4DayWeek</Link></li>
                                        <li><Link to={'/all-jobs-by-source'}>All Sources</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4">
                                <div className="widget">
                                    &nbsp;
                                </div>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-center align-items-center">
                                <div className="widget">
                                    <ul className="list-inline m-a0">
                                        <li className="mr-2"><a href="https://www.linkedin.com/company/opentoworkremote" target="_blank" rel="noopener noreferrer" className="site-button white linkedin circle "><i className="fa fa-linkedin"></i></a></li>
                                        <li className="mr-2"><a href="https://x.com/open2workremote" target="_blank" rel="noopener noreferrer" className="site-button white twitter circle "><i className="fa fa-twitter"></i></a></li>
                                        <li className="mr-2"><a href="https://github.com/maurobonfietti/remote-jobs" rel="noopener noreferrer" target="_blank" className="site-button white instagram circle "><i className="fa fa-github"></i></a></li>
                                        <li className="mr-2"><a href="https://t.me/+ClQP4DeYoL5hMGFh" rel="noopener noreferrer" target="_blank" className="site-button white linkedin circle "><i className="fa fa-telegram"></i></a></li>
                                        <li className="mr-2"><a href="https://www.facebook.com/opentoworkremote" rel="noopener noreferrer" target="_blank" className="site-button white facebook circle "><i className="fa fa-facebook"></i></a></li>
                                        <li className="mr-2 d-none"><a href="https://www.instagram.com/opentoworkremote" rel="noopener noreferrer" target="_blank" className="site-button white instagram circle "><i className="fa fa-instagram"></i></a></li>
                                        <li className="mr-2 d-none"><a href="https://discord.gg/jpgHuV7e3Y" rel="noopener noreferrer" target="_blank" className="site-button white discord circle "><i className="fa fa-discord"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <span>
                                    Building
                                    &nbsp;
                                    <Link to={"/"} className="font-italic font-weight-bold">OpenToWorkRemote.com</Link>
                                    &nbsp;
                                    with <i className="fa fa-heart m-lr5 text-red heart"></i>
                                    By
                                    &nbsp;
                                    <a href="https://x.com/maurobonfietti" className="font-italic font-weight-bold" target="_blank" rel="noopener noreferrer">Mauro</a>
                                    &nbsp;
                                    <Link to={"/contact"} className=""><img src="https://pbs.twimg.com/profile_images/1604678797625810948/kJJ8s8v2_400x400.jpg" className="rounded-circle border border-black circular-image" alt="Mauro" height="30" width="30" /></Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom-fin">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <span>
                                    <b> <Link to={"/"} className="">Home</Link></b> |
                                    <b> <Link to={"/remote-jobs-worldwide"} className="">Remote Jobs</Link></b> |
                                    <b> <Link to={"/all-jobs-by-category"} className="">Categories</Link></b> |
                                    <b> <Link to={"/all-jobs-by-location"} className="">Locations</Link></b> |
                                    <b> <Link to={"/all-jobs-by-source"} className="">Sources</Link></b> |
                                    <b> <Link to={"/try-remote-jobs-in-your-inbox"} className="">Subscribe</Link></b> |
                                    <b> <Link to={"/sponsors"} className="">Sponsors</Link></b> |
                                    <b> <Link to={"/blog"} className="">Blog</Link></b> |
                                    <b> <Link to={"/opentowork"} className="">OpenToWork</Link></b> |
                                    <b> <Link to={"/api"} className="">Api</Link></b> |
                                    <b> <Link to={"/tools"} className="">Tools</Link></b> |
                                    <b> <Link to={"/contact"} className="">Contact</Link></b>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
};

export default Footer;
