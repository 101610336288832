import React, {Component} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer2';
import { Helmet } from 'react-helmet';
import ByCountry from './../Element/ByCountry';

class AllJobsByCountry extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>Discover All Jobs by Country</title>
                    <meta name="description" content="Explore a variety of job opportunities categorized by countries around the world. Find your next remote work opportunity. #OpenToWorkRemote" />
                    <link rel="canonical" href="https://www.opentoworkremote.com/all-jobs-by-country" />
                </Helmet>

                <ByCountry />

                <Footer />
            </div>
        );
    }
};

export default AllJobsByCountry;
